import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { 
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  onSnapshot,
  orderBy,
  serverTimestamp,
  FirestoreError,
  getDoc,
  setDoc,
  getDocs,
  increment
} from 'firebase/firestore';
import { db } from './lib/firebase';
import type { Spyndl, Subject } from './types';

interface StoreState {
  spyndls: Spyndl[];
  subjects: Subject[];
  recentlyViewed: Spyndl[];
  error: string | null;
  isOnline: boolean;
  activeView: 'discover' | 'subjects' | 'study-sets' | 'cover-letter';
  savedDocuments: {
    resume: string | null;
    coverLetterSample: string | null;
  };
  setError: (error: string | null) => void;
  setActiveView: (view: 'discover' | 'subjects' | 'study-sets' | 'cover-letter') => void;
  setOnlineStatus: (online: boolean) => void;
  setSavedDocument: (type: 'resume' | 'coverLetterSample', content: string) => void;
  addSpyndl: (spyndl: Omit<Spyndl, 'id'>) => Promise<string>;
  updateSpyndl: (id: string, updates: Partial<Spyndl>) => Promise<void>;
  deleteSpyndl: (id: string) => Promise<void>;
  savePublicSpyndl: (spyndlId: string, userId: string) => Promise<void>;
  addSubject: (name: string, userId: string) => Promise<void>;
  updateSubject: (id: string, updates: Partial<Subject>) => Promise<void>;
  deleteSubject: (id: string) => Promise<void>;
  addToRecentlyViewed: (spyndl: Spyndl) => void;
  initializeListeners: (userId: string) => () => void;
}

const MAX_RECENT_ITEMS = 10;

export const useStore = create<StoreState>()(
  persist(
    (set, get) => ({
      spyndls: [],
      subjects: [],
      recentlyViewed: [],
      error: null,
      isOnline: navigator.onLine,
      activeView: 'study-sets',
      savedDocuments: {
        resume: null,
        coverLetterSample: null
      },

      setError: (error) => set({ error }),
      setActiveView: (view) => set({ activeView: view }),
      setOnlineStatus: (online) => set({ isOnline: online }),
      setSavedDocument: (type, content) => 
        set(state => ({
          savedDocuments: {
            ...state.savedDocuments,
            [type]: content
          }
        })),

      addToRecentlyViewed: (spyndl) => {
        set(state => {
          const filtered = state.recentlyViewed.filter(s => s.id !== spyndl.id);
          return {
            recentlyViewed: [spyndl, ...filtered].slice(0, MAX_RECENT_ITEMS)
          };
        });
      },

      addSpyndl: async (spyndl) => {
        try {
          const docRef = await addDoc(collection(db, 'spyndls'), {
            ...spyndl,
            isPublic: false,
            saves: 0,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });

          if (spyndl.note.content.trim()) {
            const publicSpyndl = {
              ...spyndl,
              userId: 'public',
              isPublic: true,
              originalId: docRef.id,
              saves: 0,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
            };
            await addDoc(collection(db, 'spyndls'), publicSpyndl);
          }

          set({ error: null });
          return docRef.id;
        } catch (error) {
          console.error('Error adding study set:', error);
          const errorMessage = error instanceof Error ? error.message : 'Failed to create study set';
          set({ error: errorMessage });
          throw new Error(errorMessage);
        }
      },

      updateSpyndl: async (id, updates) => {
        try {
          const docRef = doc(db, 'spyndls', id);
          await updateDoc(docRef, {
            ...updates,
            updatedAt: serverTimestamp(),
          });

          const publicQuery = query(
            collection(db, 'spyndls'),
            where('originalId', '==', id)
          );
          const publicDocs = await getDocs(publicQuery);
          
          if (!publicDocs.empty) {
            const publicDoc = publicDocs.docs[0];
            await updateDoc(doc(db, 'spyndls', publicDoc.id), {
              ...updates,
              updatedAt: serverTimestamp(),
            });
          }

          set({ error: null });
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to update study set';
          set({ error: errorMessage });
          throw error;
        }
      },

      deleteSpyndl: async (id) => {
        try {
          await deleteDoc(doc(db, 'spyndls', id));
          
          const publicQuery = query(
            collection(db, 'spyndls'),
            where('originalId', '==', id)
          );
          const publicDocs = await getDocs(publicQuery);
          
          if (!publicDocs.empty) {
            const publicDoc = publicDocs.docs[0];
            await deleteDoc(doc(db, 'spyndls', publicDoc.id));
          }

          set({ error: null });
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to delete study set';
          set({ error: errorMessage });
          throw error;
        }
      },

      savePublicSpyndl: async (spyndlId, userId) => {
        try {
          const publicDoc = await getDoc(doc(db, 'spyndls', spyndlId));
          if (!publicDoc.exists()) throw new Error('Study set not found');

          await updateDoc(doc(db, 'spyndls', spyndlId), {
            saves: increment(1)
          });

          const publicData = publicDoc.data();
          const savedSpyndl = {
            ...publicData,
            userId,
            isPublic: false,
            savedFrom: spyndlId,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          };

          await addDoc(collection(db, 'spyndls'), savedSpyndl);
          set({ error: null });
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to save study set';
          set({ error: errorMessage });
          throw error;
        }
      },

      addSubject: async (name, userId) => {
        try {
          const timestamp = serverTimestamp();
          const docRef = await addDoc(collection(db, 'subjects'), {
            name,
            userId,
            color: '#' + Math.floor(Math.random()*16777215).toString(16),
            createdAt: timestamp,
            updatedAt: timestamp,
          });

          const docSnap = await getDoc(docRef);
          const newSubject = {
            id: docRef.id,
            ...docSnap.data(),
            createdAt: new Date(),
            updatedAt: new Date(),
          } as Subject;

          set(state => ({
            subjects: [newSubject, ...state.subjects],
            error: null
          }));
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to add subject';
          set({ error: errorMessage });
          throw error;
        }
      },

      updateSubject: async (id, updates) => {
        try {
          const docRef = doc(db, 'subjects', id);
          await updateDoc(docRef, {
            ...updates,
            updatedAt: serverTimestamp(),
          });
          set({ error: null });
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to update subject';
          set({ error: errorMessage });
          throw error;
        }
      },

      deleteSubject: async (id) => {
        try {
          await deleteDoc(doc(db, 'subjects', id));
          set(state => ({
            subjects: state.subjects.filter(s => s.id !== id),
            error: null
          }));
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to delete subject';
          set({ error: errorMessage });
          throw error;
        }
      },

      initializeListeners: (userId) => {
        const handleOnline = () => set({ isOnline: true });
        const handleOffline = () => set({ isOnline: false });
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        const unsubscribers: (() => void)[] = [];

        // Listen for all spyndls (both private and public)
        const spyndlsUnsubscribe = onSnapshot(
          query(
            collection(db, 'spyndls'),
            orderBy('updatedAt', 'desc')
          ),
          (snapshot) => {
            const spyndls = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt?.toDate() || new Date(),
              updatedAt: doc.data().updatedAt?.toDate() || new Date(),
            } as Spyndl));
            set({ spyndls, error: null });
          },
          (error: FirestoreError) => set({ error: error.message })
        );
        unsubscribers.push(spyndlsUnsubscribe);

        // Listen for user's subjects
        const subjectsUnsubscribe = onSnapshot(
          query(
            collection(db, 'subjects'),
            where('userId', '==', userId),
            orderBy('createdAt', 'desc')
          ),
          (snapshot) => {
            const subjects = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt?.toDate() || new Date(),
              updatedAt: doc.data().updatedAt?.toDate() || new Date(),
            } as Subject));
            set({ subjects, error: null });
          },
          (error: FirestoreError) => set({ error: error.message })
        );
        unsubscribers.push(subjectsUnsubscribe);

        // Return cleanup function
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
          unsubscribers.forEach(unsubscribe => unsubscribe());
        };
      },
    }),
    {
      name: 'acely-storage',
      partialize: (state) => ({
        recentlyViewed: state.recentlyViewed,
        savedDocuments: state.savedDocuments,
      }),
    }
  )
);