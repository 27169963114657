import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../store';
import { ArrowLeft } from 'lucide-react';
import { NoteEditor } from './NoteEditor';
import { generateSlug } from '../lib/utils';

export function NoteView({ userId }: { userId: string }) {
  const { id, slug } = useParams();
  const navigate = useNavigate();
  const { spyndls } = useStore();

  const spyndl = spyndls.find(s => s.id === id);
  const titleSlug = spyndl ? generateSlug(spyndl.title) : '';

  if (!spyndl || titleSlug !== slug) {
    navigate('/study-sets');
    return null;
  }

  return (
    <div className="h-full flex flex-col">
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center gap-4">
            <button
              onClick={() => navigate(`/study-sets/${id}`)}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5" />
              Back to Study Set
            </button>
            <h2 className="text-xl font-semibold">{spyndl.title}</h2>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <NoteEditor
          content={spyndl.note.content}
          onUpdate={() => {}}
          readOnly={true}
        />
      </div>
    </div>
  );
}