import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Notes } from './components/Notes';
import { Subjects } from './components/Subjects';
import { Discover } from './components/Discover';
import { AuthForm } from './components/auth/AuthForm';
import { useStore } from './store';
import { useAuth } from './hooks/useAuth';
import { AlertCircle } from 'lucide-react';
import { Navigation } from './components/Navigation';
import { StudySetView } from './components/StudySetView';
import { FlashcardView } from './components/FlashcardView';
import { NoteView } from './components/NoteView';
import { CoverLetterWriter } from './components/CoverLetterWriter';
import { Profile } from './components/Profile';

export default function App() {
  const { user, loading } = useAuth();
  const { isOnline } = useStore();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-600 border-t-transparent"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <AuthForm />
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <Navigation />

        <div className="min-h-screen transition-all duration-300">
          {!isOnline && (
            <div className="bg-yellow-50 p-2 flex items-center justify-center gap-2 text-yellow-800 mt-16">
              <AlertCircle className="w-4 h-4" />
              <span className="text-sm">You're offline. Changes will sync when you're back online.</span>
            </div>
          )}

          <main className="min-h-screen p-4">
            <Routes>
              <Route path="/" element={<Notes userId={user.uid} />} />
              <Route path="/discover" element={<Discover userId={user.uid} />} />
              <Route path="/discover/:titleSlug/:contentSlug" element={<StudySetView userId={user.uid} isReadOnly />} />
              <Route path="/subjects" element={<Subjects userId={user.uid} />} />
              <Route path="/study-sets" element={<Notes userId={user.uid} />} />
              <Route path="/study-sets/:titleSlug/:contentSlug" element={<StudySetView userId={user.uid} />} />
              <Route path="/study-sets/:titleSlug/:contentSlug/notes" element={<NoteView userId={user.uid} />} />
              <Route path="/study-sets/:titleSlug/:contentSlug/cards" element={<FlashcardView userId={user.uid} />} />
              <Route path="/cover-letter" element={<CoverLetterWriter userId={user.uid} />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}