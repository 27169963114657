import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStore } from '../store';
import { auth } from '../lib/firebase';
import { Search, FolderOpen, BookOpen, LogOut, Menu, X, FileText, User } from 'lucide-react';

export function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setActiveView } = useStore();
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { id: 'discover', path: '/discover', Icon: Search, label: 'Find Study Sets' },
    { id: 'subjects', path: '/subjects', Icon: FolderOpen, label: 'Subjects' },
    { id: 'study-sets', path: '/study-sets', Icon: BookOpen, label: 'Study Sets' },
    { id: 'cover-letter', path: '/cover-letter', Icon: FileText, label: 'Cover Letter Writer' },
    { id: 'profile', path: '/profile', Icon: User, label: 'Profile' },
  ];

  const handleNavigation = (path: string, id: string) => {
    setActiveView(id as 'discover' | 'subjects' | 'study-sets' | 'cover-letter');
    navigate(path);
    setIsOpen(false);
  };

  return (
    <>
      {/* Top Header */}
      <div className="fixed top-0 left-0 right-0 h-16 bg-white border-b z-40">
        <div className="h-full px-4 flex items-center">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="p-2 hover:bg-primary-50 rounded-lg text-primary-600"
          >
            {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
          <div className="flex-1 flex justify-center">
            <span className="text-2xl font-bold text-primary-600 text-center">ACELY</span>
          </div>
          <div className="w-10" /> {/* Spacer to balance the menu button */}
        </div>
      </div>

      {/* Side Navigation */}
      <nav 
        className={`fixed inset-y-0 left-0 bg-white border-r flex flex-col flex-shrink-0 transition-all duration-300 z-30 ${
          isOpen ? 'w-64 translate-x-0' : 'w-0 -translate-x-full'
        }`}
        style={{ top: '64px' }}
      >
        <div className="flex-1 py-4 overflow-y-auto">
          {navItems.map(({ id, path, Icon, label }) => (
            <button
              key={id}
              onClick={() => handleNavigation(path, id)}
              className={`
                w-full h-12 flex items-center gap-3 px-4
                relative transition-colors
                hover:bg-primary-50
                ${location.pathname === path ? 'text-primary-600 bg-primary-50' : 'text-gray-600'}
              `}
            >
              <Icon className="w-6 h-6 flex-shrink-0" />
              <span className="whitespace-nowrap">{label}</span>
              {location.pathname === path && (
                <div className="absolute left-0 top-0 bottom-0 w-1 bg-primary-600" />
              )}
            </button>
          ))}
        </div>
      </nav>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Content Spacer */}
      <div className="h-16" />
    </>
  );
}