import React, { useState, useEffect } from 'react';
import { useStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { Spyndl } from '../types';
import { CreateSpyndlModal } from './CreateSpyndlModal';
import { StudySetList } from './StudySetList';
import { generateStudySetUrl } from '../lib/utils';
import { SearchBar } from './SearchBar';
import { PageHeader } from './PageHeader';

interface NotesProps {
  userId: string;
}

export function Notes({ userId }: NotesProps) {
  const { 
    spyndls = [], // Provide default empty array
    addSpyndl,
    updateSpyndl,
    deleteSpyndl,
    initializeListeners 
  } = useStore();
  
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userId) {
      const cleanup = initializeListeners(userId);
      return () => cleanup();
    }
  }, [userId, initializeListeners]);

  // Filter study sets - only show private ones owned by the user
  const userSpyndls = (spyndls || []).filter(spyndl => 
    !spyndl.isPublic && 
    spyndl.userId === userId &&
    (spyndl.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    spyndl.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  const createSpyndl = async (title: string) => {
    try {
      const newSpyndl: Omit<Spyndl, 'id'> = {
        userId,
        title,
        subject: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        isPublic: false,
        note: {
          content: '',
          isFullScreen: false,
        },
        deck: {
          cards: [],
        },
        tags: [],
      };

      const spyndlId = await addSpyndl(newSpyndl);
      const url = generateStudySetUrl(title, '', userSpyndls.length + 1);
      navigate(`/study-sets/${url}`);
      setShowCreateModal(false);
    } catch (error) {
      console.error('Error creating study set:', error);
      setError('Failed to create study set');
    }
  };

  const handleSelect = (spyndl: Spyndl) => {
    const index = userSpyndls.findIndex(s => s.id === spyndl.id) + 1;
    const url = generateStudySetUrl(spyndl.title, spyndl.note.content, index);
    navigate(`/study-sets/${url}`);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteSpyndl(id);
    } catch (error) {
      console.error('Error deleting study set:', error);
      setError('Failed to delete study set');
    }
  };

  const handleUpdate = async (id: string, updates: Partial<Spyndl>) => {
    try {
      await updateSpyndl(id, updates);
    } catch (error) {
      console.error('Error updating study set:', error);
      setError('Failed to update study set');
    }
  };

  return (
    <div className="max-w-7xl mx-auto">
      <PageHeader title="My Study Sets">
        <button
          onClick={() => setShowCreateModal(true)}
          className="btn-primary"
        >
          <Plus className="w-5 h-5" />
          New Study Set
        </button>
      </PageHeader>

      <div className="mb-6">
        <SearchBar
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder="Search your study sets..."
        />
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <StudySetList
        spyndls={userSpyndls}
        onSelect={handleSelect}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        emptyMessage="No study sets yet"
        isPersonal={true}
      />

      <CreateSpyndlModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onCreateSpyndl={createSpyndl}
      />
    </div>
  );
}