import { z } from 'zod';
import type { Flashcard, Question } from '../types';
import nlp from 'compromise';

// Schema validation
const flashcardSchema = z.object({
  front: z.string().min(1),
  back: z.string().min(1)
});

const questionSchema = z.object({
  text: z.string().min(1),
  options: z.array(z.string()).length(4),
  correctAnswer: z.number().min(0).max(3),
  explanation: z.string().min(1)
});

// Extract JSON from AI response
export async function extractJSON(text: string): Promise<any> {
  const jsonRegex = /\[[\s\S]*\]/;
  const match = text.match(jsonRegex);
  
  if (!match) {
    throw new Error('No JSON array found in response');
  }

  try {
    return JSON.parse(match[0]);
  } catch (error) {
    console.error('Error extracting JSON:', error);
    throw new Error('Failed to parse response');
  }
}

// Validate flashcards array
export function validateFlashcards(data: any): Flashcard[] {
  if (!Array.isArray(data)) {
    throw new Error('Invalid flashcards format');
  }

  return data.map(item => {
    const validated = flashcardSchema.parse(item);
    return {
      id: crypto.randomUUID(),
      front: validated.front.trim(),
      back: validated.back.trim(),
      confidence: 1,
      lastReviewed: new Date()
    };
  });
}

// Validate quiz questions array
export function validateQuestions(data: any): Question[] {
  if (!Array.isArray(data)) {
    throw new Error('Invalid questions format');
  }

  return data.map(item => {
    const validated = questionSchema.parse(item);
    return {
      id: crypto.randomUUID(),
      ...validated
    };
  });
}

// Calculate optimal number of items based on content
export function calculateOptimalItems(content: string): { cards: number; questions: number } {
  const plainText = content.replace(/<[^>]*>/g, '').trim();
  const words = plainText.split(/\s+/).length;
  const doc = nlp(plainText);
  const sentences = doc.sentences().length;
  const topics = (content.match(/<h[1-6][^>]*>.*?<\/h[1-6]>/g) || []).length;
  const paragraphs = (content.match(/<p[^>]*>.*?<\/p>/g) || []).length;
  
  const baseCards = Math.ceil(words / 200);
  const basedOnTopics = Math.max(topics, Math.ceil(paragraphs / 2));
  const basedOnSentences = Math.ceil(sentences / 5);
  
  const recommendedCards = Math.min(
    Math.max(
      Math.round((baseCards + basedOnTopics + basedOnSentences) / 3),
      5
    ),
    20
  );
  
  const recommendedQuestions = Math.min(
    Math.max(
      Math.round(recommendedCards * 0.6),
      3
    ),
    10
  );
  
  return {
    cards: recommendedCards,
    questions: recommendedQuestions
  };
}

// Retry function with exponential backoff
export async function retryWithBackoff<T>(
  fn: () => Promise<T>,
  maxRetries = 3,
  baseDelay = 1000
): Promise<T> {
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (i === maxRetries - 1) throw error;
      const delay = baseDelay * Math.pow(2, i);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  throw new Error('Max retries exceeded');
}

// Generate URL-friendly slug
export function generateSlug(text: string): string {
  if (!text || text.trim().length === 0) {
    return 'untitled';
  }
  
  return text
    .toLowerCase()
    .replace(/<[^>]*>/g, '')
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .slice(0, 100)
    .replace(/^-+|-+$/g, '');
}

// Generate content preview
export function generateContentPreview(content: string, index?: number): string {
  if (!content || content.trim().length === 0) {
    return `set-${index || 1}`;
  }
  
  const plainText = content.replace(/<[^>]*>/g, '').trim();
  const words = plainText.split(/\s+/).slice(0, 100);
  return generateSlug(words.join(' '));
}

// Generate study set URL
export function generateStudySetUrl(title: string, content: string, index?: number): string {
  const titleSlug = generateSlug(title);
  const contentSlug = generateContentPreview(content, index);
  return `${titleSlug}/${contentSlug}`;
}

// Extract text from resume file
export async function extractResumeText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = async (event) => {
      try {
        const content = event.target?.result;
        if (!content) {
          reject(new Error('Failed to read file content'));
          return;
        }

        if (file.type === 'text/plain') {
          resolve(content as string);
        } else if (file.type === 'application/pdf') {
          resolve(content as string);
        } else {
          reject(new Error('Unsupported file type'));
        }
      } catch (err) {
        reject(new Error('Failed to process file content'));
      }
    };

    reader.onerror = () => reject(new Error('Failed to read file'));
    
    if (file.type === 'text/plain') {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
}