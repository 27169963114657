import React, { useState } from 'react';
import { format } from 'date-fns';
import { Edit2, Trash2, Save, X, BookmarkPlus } from 'lucide-react';
import { useStore } from '../store';
import type { Spyndl } from '../types';

interface StudySetCardProps {
  spyndl: Spyndl;
  onClick: () => void;
  onDelete?: (id: string) => Promise<void>;
  onUpdate?: (id: string, updates: Partial<Spyndl>) => Promise<void>;
  isPersonal?: boolean;
  userId?: string;
}

export function StudySetCard({ 
  spyndl, 
  onClick, 
  onDelete, 
  onUpdate,
  isPersonal = false,
  userId
}: StudySetCardProps) {
  const { savePublicSpyndl } = useStore();
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(spyndl.title);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!onDelete) return;
    
    if (window.confirm('Are you sure you want to delete this study set?')) {
      try {
        setIsLoading(true);
        await onDelete(spyndl.id);
      } catch (error) {
        console.error('Failed to delete study set:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleUpdate = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!onUpdate) return;
    
    try {
      setIsLoading(true);
      await onUpdate(spyndl.id, { title });
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update study set:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!userId) return;

    try {
      setIsSaving(true);
      await savePublicSpyndl(spyndl.id, userId);
    } catch (error) {
      console.error('Failed to save study set:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    setTitle(spyndl.title);
    setIsEditing(false);
  };

  return (
    <div
      onClick={onClick}
      className="card cursor-pointer relative group"
    >
      {isEditing ? (
        <div onClick={e => e.stopPropagation()} className="mb-2">
          <input
            type="text"
            value={title}
            onChange={e => setTitle(e.target.value)}
            className="input-primary"
            placeholder="Enter title"
            autoFocus
          />
          <div className="flex justify-end gap-2 mt-2">
            <button
              onClick={handleCancel}
              disabled={isLoading}
              className="p-1 text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded"
            >
              <X className="w-4 h-4" />
            </button>
            <button
              onClick={handleUpdate}
              disabled={isLoading || !title.trim() || title === spyndl.title}
              className="p-1 text-primary-600 hover:text-primary-900 hover:bg-primary-50 rounded"
            >
              <Save className="w-4 h-4" />
            </button>
          </div>
        </div>
      ) : (
        <>
          <h3 className="text-lg font-medium mb-2">{spyndl.title}</h3>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <span>{format(new Date(spyndl.updatedAt), 'MMM d, yyyy')}</span>
            <span>•</span>
            <span>{spyndl.deck.cards.length} cards</span>
            {spyndl.saves !== undefined && (
              <>
                <span>•</span>
                <span>{spyndl.saves} saves</span>
              </>
            )}
          </div>
        </>
      )}

      {!isEditing && (
        <div className="absolute top-4 right-4 flex gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
          {isPersonal ? (
            <>
              <button
                onClick={handleEdit}
                className="p-1 text-gray-600 hover:text-primary-600 hover:bg-primary-50 rounded"
              >
                <Edit2 className="w-4 h-4" />
              </button>
              <button
                onClick={handleDelete}
                className="p-1 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded"
              >
                <Trash2 className="w-4 h-4" />
              </button>
            </>
          ) : userId && (
            <button
              onClick={handleSave}
              disabled={isSaving}
              className="p-1 text-gray-600 hover:text-primary-600 hover:bg-primary-50 rounded"
              title="Save to your study sets"
            >
              <BookmarkPlus className={`w-4 h-4 ${isSaving ? 'animate-pulse' : ''}`} />
            </button>
          )}
        </div>
      )}
    </div>
  );
}