import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDNqcbp5co94otWioYFP6t5JqQea5hnPI0",
  authDomain: "prepd-7b489.firebaseapp.com",
  projectId: "prepd-7b489",
  storageBucket: "prepd-7b489.appspot.com",
  messagingSenderId: "141425811876",
  appId: "1:141425811876:web:cf1e401d2c43d18fac6131"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Enable offline persistence
try {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser does not support persistence.');
    }
  });
} catch (err) {
  console.warn('Error enabling persistence:', err);
}

export { db, auth };