import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, BookmarkPlus, Loader2, Menu } from 'lucide-react';
import { NoteEditor } from './NoteEditor';
import { FlashcardEditor } from './FlashcardEditor';
import { PracticeQuiz } from './PracticeQuiz';
import { useStore } from '../store';
import { generateSlug, generateContentPreview } from '../lib/utils';
import type { Spyndl } from '../types';

interface StudySetViewProps {
  userId: string;
  isReadOnly?: boolean;
}

export function StudySetView({ userId, isReadOnly = false }: StudySetViewProps) {
  const { titleSlug, contentSlug } = useParams();
  const navigate = useNavigate();
  const { spyndls, updateSpyndl, savePublicSpyndl, addToRecentlyViewed } = useStore();
  const [activeTab, setActiveTab] = useState<'notes' | 'flashcards' | 'quiz'>('notes');
  const [isSaving, setIsSaving] = useState(false);
  const [localContent, setLocalContent] = useState('');
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const spyndl = spyndls.find(s => {
    const sTitleSlug = generateSlug(s.title);
    const sContentSlug = generateContentPreview(s.note.content);
    return sTitleSlug === titleSlug && (!contentSlug || sContentSlug === contentSlug);
  });

  useEffect(() => {
    if (spyndl) {
      addToRecentlyViewed(spyndl);
      setLocalContent(spyndl.note.content);
    }
  }, [spyndl, addToRecentlyViewed]);

  useEffect(() => {
    if (!spyndl) {
      navigate(isReadOnly ? '/discover' : '/study-sets');
    }
  }, [spyndl, navigate, isReadOnly]);

  if (!spyndl) return null;

  const handleBack = () => {
    navigate(isReadOnly ? '/discover' : '/study-sets');
  };

  const handleSave = async () => {
    if (!isReadOnly || !userId || isSaving) return;
    
    try {
      setIsSaving(true);
      await savePublicSpyndl(spyndl.id, userId);
    } catch (error) {
      console.error('Failed to save study set:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdate = async (updates: Partial<Spyndl>) => {
    if (isReadOnly) return;

    try {
      await updateSpyndl(spyndl.id, updates);
      
      if (updates.title) {
        const newTitleSlug = generateSlug(updates.title);
        const newContentSlug = generateContentPreview(spyndl.note.content);
        const basePath = isReadOnly ? '/discover' : '/study-sets';
        navigate(`${basePath}/${newTitleSlug}/${newContentSlug}`, { replace: true });
      }
    } catch (error) {
      console.error('Failed to update study set:', error);
    }
  };

  const handleNoteUpdate = (content: string) => {
    setLocalContent(content);
    handleUpdate({ 
      note: { ...spyndl.note, content } 
    });
  };

  return (
    <div className="h-full flex flex-col">
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4 py-4">
            <div className="flex items-center gap-4 min-w-0">
              <button
                onClick={handleBack}
                className="flex-shrink-0 flex items-center gap-2 text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="w-5 h-5" />
                <span className="hidden sm:inline">Back</span>
              </button>
              <h2 className="text-xl font-semibold truncate">{spyndl.title}</h2>
            </div>

            <div className="flex items-center justify-between sm:justify-end gap-4 ml-auto">
              {isReadOnly && (
                <button
                  onClick={handleSave}
                  disabled={isSaving}
                  className="btn-primary whitespace-nowrap"
                >
                  {isSaving ? (
                    <>
                      <Loader2 className="w-5 h-5 animate-spin" />
                      <span className="hidden sm:inline">Saving...</span>
                    </>
                  ) : (
                    <>
                      <BookmarkPlus className="w-5 h-5" />
                      <span className="hidden sm:inline">Save Study Set</span>
                    </>
                  )}
                </button>
              )}

              {/* Mobile Menu Button */}
              <button
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                className="sm:hidden p-2 text-gray-600 hover:text-gray-900"
              >
                <Menu className="w-6 h-6" />
              </button>

              {/* Desktop Navigation */}
              <div className="hidden sm:flex gap-2">
                <button
                  onClick={() => setActiveTab('notes')}
                  className={`px-3 py-1.5 rounded-lg transition-colors ${
                    activeTab === 'notes'
                      ? 'bg-primary-50 text-primary-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  Notes
                </button>
                <button
                  onClick={() => setActiveTab('flashcards')}
                  className={`px-3 py-1.5 rounded-lg transition-colors ${
                    activeTab === 'flashcards'
                      ? 'bg-primary-50 text-primary-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  Flashcards
                </button>
                <button
                  onClick={() => setActiveTab('quiz')}
                  className={`px-3 py-1.5 rounded-lg transition-colors ${
                    activeTab === 'quiz'
                      ? 'bg-primary-50 text-primary-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  Practice Quiz
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Navigation Menu */}
          {showMobileMenu && (
            <div className="sm:hidden border-t py-2 space-y-1">
              <button
                onClick={() => {
                  setActiveTab('notes');
                  setShowMobileMenu(false);
                }}
                className={`w-full px-4 py-2 text-left ${
                  activeTab === 'notes'
                    ? 'bg-primary-50 text-primary-600'
                    : 'text-gray-600'
                }`}
              >
                Notes
              </button>
              <button
                onClick={() => {
                  setActiveTab('flashcards');
                  setShowMobileMenu(false);
                }}
                className={`w-full px-4 py-2 text-left ${
                  activeTab === 'flashcards'
                    ? 'bg-primary-50 text-primary-600'
                    : 'text-gray-600'
                }`}
              >
                Flashcards
              </button>
              <button
                onClick={() => {
                  setActiveTab('quiz');
                  setShowMobileMenu(false);
                }}
                className={`w-full px-4 py-2 text-left ${
                  activeTab === 'quiz'
                    ? 'bg-primary-50 text-primary-600'
                    : 'text-gray-600'
                }`}
              >
                Practice Quiz
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        {activeTab === 'notes' && (
          <NoteEditor
            content={localContent}
            onUpdate={handleNoteUpdate}
            readOnly={isReadOnly}
          />
        )}
        {activeTab === 'flashcards' && (
          <FlashcardEditor
            cards={spyndl.deck.cards}
            noteContent={spyndl.note.content}
            onUpdate={(cards) => handleUpdate({ 
              deck: { ...spyndl.deck, cards } 
            })}
            readOnly={isReadOnly}
            spyndlId={spyndl.id}
          />
        )}
        {activeTab === 'quiz' && (
          <PracticeQuiz
            noteContent={spyndl.note.content}
            spyndlId={spyndl.id}
            onUpdate={handleUpdate}
            readOnly={isReadOnly}
          />
        )}
      </div>
    </div>
  );
}