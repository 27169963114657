import React, { useCallback, useEffect, memo } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import { Bold, Italic, List, ListOrdered, Heading, CheckSquare } from 'lucide-react';
import debounce from 'lodash.debounce';

interface NoteEditorProps {
  content: string;
  onUpdate: (content: string) => void;
  readOnly?: boolean;
}

const MenuBar = memo(({ editor }: { editor: any }) => {
  if (!editor) return null;

  return (
    <div className="border-b flex items-center gap-1 overflow-x-auto bg-white">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`p-1.5 rounded hover:bg-primary-50 ${editor.isActive('bold') ? 'bg-primary-50 text-primary-600' : ''}`}
        title="Bold"
      >
        <Bold className="w-4 h-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`p-1.5 rounded hover:bg-primary-50 ${editor.isActive('italic') ? 'bg-primary-50 text-primary-600' : ''}`}
        title="Italic"
      >
        <Italic className="w-4 h-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`p-1.5 rounded hover:bg-primary-50 ${editor.isActive('heading', { level: 2 }) ? 'bg-primary-50 text-primary-600' : ''}`}
        title="Heading"
      >
        <Heading className="w-4 h-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`p-1.5 rounded hover:bg-primary-50 ${editor.isActive('bulletList') ? 'bg-primary-50 text-primary-600' : ''}`}
        title="Bullet list"
      >
        <List className="w-4 h-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`p-1.5 rounded hover:bg-primary-50 ${editor.isActive('orderedList') ? 'bg-primary-50 text-primary-600' : ''}`}
        title="Numbered list"
      >
        <ListOrdered className="w-4 h-4" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className={`p-1.5 rounded hover:bg-primary-50 ${editor.isActive('taskList') ? 'bg-primary-50 text-primary-600' : ''}`}
        title="Task list"
      >
        <CheckSquare className="w-4 h-4" />
      </button>
    </div>
  );
});

MenuBar.displayName = 'MenuBar';

export const NoteEditor = memo(({ content = '', onUpdate, readOnly = false }: NoteEditorProps) => {
  const debouncedUpdate = useCallback(
    debounce((content: string) => {
      onUpdate(content);
    }, 500),
    [onUpdate]
  );

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3],
        },
        paragraph: {
          HTMLAttributes: {
            class: 'mb-3',
          },
        },
      }),
      Highlight.configure({
        multicolor: true,
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
    ],
    content,
    editorProps: {
      attributes: {
        class: 'prose prose-lg max-w-none focus:outline-none min-h-[calc(100vh-7rem)] px-4 md:px-6 py-2 leading-relaxed',
      },
    },
    onUpdate: ({ editor }) => {
      if (!readOnly) {
        const content = editor.getHTML();
        debouncedUpdate(content);
      }
    },
    parseOptions: {
      preserveWhitespace: true,
    },
    editable: !readOnly,
  });

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [editor, content]);

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden bg-white">
      {!readOnly && <MenuBar editor={editor} />}
      <div className="flex-1 overflow-y-auto">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
});

NoteEditor.displayName = 'NoteEditor';