import { GoogleGenerativeAI } from '@google/generative-ai';
import { extractJSON, validateFlashcards, validateQuestions, retryWithBackoff, calculateOptimalItems } from './utils';
import type { Flashcard, Question } from '../types';

const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY || 'AIzaSyCAiblRr00udDvBQTu8AbO59HbwDs9GY_8';
const genAI = new GoogleGenerativeAI(API_KEY);
const model = genAI.getGenerativeModel({ model: 'gemini-pro' });

async function generateContent(prompt: string): Promise<string> {
  const result = await model.generateContent(prompt);
  const response = await result.response;
  return response.text();
}

export async function generateFlashcards(noteContent: string): Promise<Flashcard[]> {
  if (!noteContent.trim()) {
    throw new Error('Please add some content to your notes first');
  }

  const { cards } = calculateOptimalItems(noteContent);

  const prompt = `Create study flashcards from this content. Return ONLY a JSON array of objects.
Each object must have exactly two properties:
- "front": a question or key concept
- "back": the answer or explanation
Create exactly ${cards} cards. Keep each card focused and concise.

Example: [{"front": "What is photosynthesis?", "back": "The process by which plants convert light energy into chemical energy"}]

Content: ${noteContent}`;

  try {
    const data = await retryWithBackoff(() => generateContent(prompt));
    const parsedData = await extractJSON(data);
    return validateFlashcards(parsedData);
  } catch (error) {
    console.error('Error generating flashcards:', error);
    throw new Error('Failed to generate flashcards. Please try again.');
  }
}

export async function generateQuizQuestions(noteContent: string): Promise<Question[]> {
  if (!noteContent.trim()) {
    throw new Error('Please add some content to your notes first');
  }

  const { questions } = calculateOptimalItems(noteContent);

  const prompt = `Create a multiple choice quiz from this content. Return ONLY a JSON array of objects.
Each object must have these properties:
- "text": the question
- "options": array of exactly 4 answer choices
- "correctAnswer": number 0-3 indicating correct option
- "explanation": why the answer is correct
Create exactly ${questions} questions.

Example: [{"text": "What is the primary function of mitochondria?", "options": ["Energy production", "Protein synthesis", "Cell division", "Waste removal"], "correctAnswer": 0, "explanation": "Mitochondria are known as the powerhouse of the cell, producing ATP through cellular respiration."}]

Content: ${noteContent}`;

  try {
    const data = await retryWithBackoff(() => generateContent(prompt));
    const parsedData = await extractJSON(data);
    return validateQuestions(parsedData);
  } catch (error) {
    console.error('Error generating quiz questions:', error);
    throw new Error('Failed to generate quiz questions. Please try again.');
  }
}

export async function generateCoverLetter(
  resumeText: string,
  jobDescription: string,
  companyName: string = '',
  coverLetterSample?: string | null
): Promise<string> {
  let prompt = `Write a professional cover letter based on my resume and the job description.
Use only the skills and experience mentioned in my resume.
Make it engaging and tailored to the specific role.
${companyName ? `The company name is: ${companyName}` : ''}

My Resume:
${resumeText}

Job Description:
${jobDescription}`;

  if (coverLetterSample) {
    prompt += `\n\nPlease emulate the tone, structure, and style of this sample cover letter:
${coverLetterSample}`;
  }

  try {
    const response = await retryWithBackoff(() => generateContent(prompt));
    return response;
  } catch (error) {
    console.error('Error generating cover letter:', error);
    throw new Error('Failed to generate cover letter. Please try again.');
  }
}