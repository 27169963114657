import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { NoteEditor } from './NoteEditor';
import { FlashcardEditor } from './FlashcardEditor';
import { PracticeQuiz } from './PracticeQuiz';
import { useStore } from '../store';
import type { Spyndl } from '../types';

interface SpyndlEditorProps {
  spyndl: Spyndl;
  onBack: () => void;
  onUpdate: (updates: Partial<Spyndl>) => Promise<void>;
}

export function SpyndlEditor({ spyndl, onBack, onUpdate }: SpyndlEditorProps) {
  const [activeTab, setActiveTab] = useState<'notes' | 'flashcards' | 'quiz'>('notes');
  const [localSpyndl, setLocalSpyndl] = useState<Spyndl>(spyndl);

  const handleNoteUpdate = async (content: string) => {
    const updates = {
      note: {
        ...localSpyndl.note,
        content,
      },
    };
    setLocalSpyndl(prev => ({
      ...prev,
      ...updates
    }));
    await onUpdate(updates);
  };

  const handleFlashcardsUpdate = async (cards: any[]) => {
    const updates = {
      deck: {
        ...localSpyndl.deck,
        cards,
      },
    };
    setLocalSpyndl(prev => ({
      ...prev,
      ...updates
    }));
    await onUpdate(updates);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              <button
                onClick={onBack}
                className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
              >
                <ArrowLeft className="w-5 h-5" />
                Back
              </button>
              <h2 className="text-xl font-semibold truncate">{localSpyndl.title}</h2>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setActiveTab('notes')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'notes'
                    ? 'bg-primary-50 text-primary-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Notes
              </button>
              <button
                onClick={() => setActiveTab('flashcards')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'flashcards'
                    ? 'bg-primary-50 text-primary-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Flashcards
              </button>
              <button
                onClick={() => setActiveTab('quiz')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'quiz'
                    ? 'bg-primary-50 text-primary-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Practice Quiz
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        {activeTab === 'notes' && (
          <NoteEditor
            content={localSpyndl.note.content}
            onUpdate={handleNoteUpdate}
          />
        )}
        {activeTab === 'flashcards' && (
          <FlashcardEditor
            cards={localSpyndl.deck.cards}
            noteContent={localSpyndl.note.content}
            onUpdate={handleFlashcardsUpdate}
            spyndlId={localSpyndl.id}
          />
        )}
        {activeTab === 'quiz' && (
          <PracticeQuiz
            noteContent={localSpyndl.note.content}
            spyndlId={localSpyndl.id}
            onUpdate={onUpdate}
          />
        )}
      </div>
    </div>
  );
}