import React, { useState, useEffect } from 'react';
import { useStore } from '../store';
import { Plus, BookOpen } from 'lucide-react';
import { Subject, Spyndl } from '../types';
import { AddSubjectModal } from './AddSubjectModal';
import { SpyndlEditor } from './SpyndlEditor';
import { SearchBar } from './SearchBar';
import { PageHeader } from './PageHeader';

interface SubjectsProps {
  userId: string;
}

export function Subjects({ userId }: SubjectsProps) {
  const { 
    subjects, 
    spyndls,
    addSubject,
    deleteSubject,
    addSpyndl,
    updateSpyndl,
    initializeListeners 
  } = useStore();

  const [searchTerm, setSearchTerm] = useState('');
  const [showAddSubject, setShowAddSubject] = useState(false);
  const [activeSubject, setActiveSubject] = useState<Subject | null>(null);
  const [activeSpyndl, setActiveSpyndl] = useState<Spyndl | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userId) {
      const cleanup = initializeListeners(userId);
      return () => cleanup();
    }
  }, [userId, initializeListeners]);

  const filteredSubjects = subjects?.filter(subject =>
    subject.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const subjectSpyndls = spyndls?.filter(spyndl => 
    spyndl.subject === activeSubject?.id
  ) || [];

  const createSpyndl = async (title: string) => {
    if (!activeSubject) return;

    try {
      const newSpyndl: Omit<Spyndl, 'id'> = {
        userId,
        title,
        subject: activeSubject.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        note: {
          content: '',
          isFullScreen: false,
        },
        deck: {
          cards: [],
        },
        tags: [],
      };

      const spyndlId = await addSpyndl(newSpyndl);
      const createdSpyndl = spyndls.find(s => s.id === spyndlId);
      if (createdSpyndl) {
        setActiveSpyndl(createdSpyndl);
      }
    } catch (error) {
      console.error('Error creating study set:', error);
      setError('Failed to create study set');
    }
  };

  // Show spyndl editor when a spyndl is active
  if (activeSpyndl) {
    return (
      <SpyndlEditor
        spyndl={activeSpyndl}
        onBack={() => setActiveSpyndl(null)}
        onUpdate={(updates) => updateSpyndl(activeSpyndl.id, updates)}
      />
    );
  }

  // Show subject spyndls when a subject is selected
  if (activeSubject) {
    return (
      <div className="max-w-7xl mx-auto">
        <PageHeader title={activeSubject.name}>
          <div className="flex items-center gap-4">
            <button
              onClick={() => setActiveSubject(null)}
              className="btn-secondary"
            >
              Back to Subjects
            </button>
            <button
              onClick={() => createSpyndl('New Study Set')}
              className="btn-primary"
            >
              <Plus className="w-5 h-5" />
              New Study Set
            </button>
          </div>
        </PageHeader>

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {subjectSpyndls.map((spyndl) => (
            <div
              key={spyndl.id}
              onClick={() => setActiveSpyndl(spyndl)}
              className="card cursor-pointer"
            >
              <h3 className="text-lg font-medium mb-2">{spyndl.title}</h3>
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <span>{spyndl.deck.cards.length} cards</span>
              </div>
            </div>
          ))}
        </div>

        {subjectSpyndls.length === 0 && (
          <div className="text-center py-12">
            <BookOpen className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No study sets in this subject</h3>
            <p className="text-gray-500">Create a study set to get started</p>
          </div>
        )}
      </div>
    );
  }

  // Show subjects list
  return (
    <div className="max-w-7xl mx-auto">
      <PageHeader title="Subjects">
        <button
          onClick={() => setShowAddSubject(true)}
          className="btn-primary"
        >
          <Plus className="w-5 h-5" />
          New Subject
        </button>
      </PageHeader>

      <div className="mb-6">
        <SearchBar
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder="Search subjects..."
        />
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {filteredSubjects.map((subject) => (
          <div
            key={subject.id}
            className="card"
          >
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-xl font-semibold">{subject.name}</h3>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  deleteSubject(subject.id);
                }}
                className="p-1 text-red-600 hover:bg-red-50 rounded"
              >
                <Plus className="w-4 h-4 rotate-45" />
              </button>
            </div>
            <button
              onClick={() => setActiveSubject(subject)}
              className="text-primary-600 hover:text-primary-700 font-medium"
            >
              View Study Sets
            </button>
          </div>
        ))}
      </div>

      {subjects?.length === 0 && (
        <div className="text-center py-12">
          <BookOpen className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No subjects yet</h3>
          <p className="text-gray-500">Create a subject to get started</p>
        </div>
      )}

      <AddSubjectModal
        isOpen={showAddSubject}
        onClose={() => setShowAddSubject(false)}
        onAddSubject={(name) => addSubject(name, userId)}
        userId={userId}
      />
    </div>
  );
}