import React, { useState, useEffect } from 'react';
import { Plus, Wand2, Loader2, ArrowLeft, ArrowRight, RotateCw, Link, BookOpen } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { generateFlashcards } from '../lib/gemini';
import { generateSlug } from '../lib/utils';
import type { Flashcard } from '../types';

interface FlashcardEditorProps {
  cards: Flashcard[];
  noteContent: string;
  onUpdate: (cards: Flashcard[]) => void;
  readOnly?: boolean;
  spyndlId?: string;
}

export function FlashcardEditor({ 
  cards = [], 
  noteContent, 
  onUpdate, 
  readOnly = false,
  spyndlId
}: FlashcardEditorProps) {
  const [localCards, setLocalCards] = useState<Flashcard[]>(cards);
  const [isStudyMode, setIsStudyMode] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [copiedUrl, setCopiedUrl] = useState<string | null>(null);

  useEffect(() => {
    setLocalCards(cards);
  }, [cards]);

  const handleGenerateCards = async () => {
    if (!noteContent.trim()) {
      setError('Please add some content to your notes first');
      return;
    }

    try {
      setIsGenerating(true);
      setError(null);
      const generatedCards = await generateFlashcards(noteContent);
      const updatedCards = [...localCards, ...generatedCards];
      setLocalCards(updatedCards);
      onUpdate(updatedCards);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to generate flashcards';
      setError(message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleCopyUrl = (card: Flashcard, e: React.MouseEvent) => {
    e.stopPropagation();
    if (!spyndlId) return;

    const slug = generateSlug(card.front);
    const url = `${window.location.origin}/study-sets/${spyndlId}/cards/${slug}`;
    navigator.clipboard.writeText(url);
    setCopiedUrl(card.id);
  };

  const handleUpdateCard = (index: number, field: 'front' | 'back', value: string) => {
    if (readOnly) return;
    
    const updatedCards = [...localCards];
    updatedCards[index] = {
      ...updatedCards[index],
      [field]: value
    };
    setLocalCards(updatedCards);
    onUpdate(updatedCards);
  };

  const handleDeleteCard = (index: number) => {
    if (readOnly) return;
    
    const updatedCards = localCards.filter((_, i) => i !== index);
    setLocalCards(updatedCards);
    onUpdate(updatedCards);
  };

  if (isStudyMode && localCards.length > 0) {
    return (
      <div className="h-full flex flex-col bg-gray-50 p-4">
        <div className="max-w-2xl mx-auto w-full">
          <div className="flex justify-between items-center mb-6">
            <button
              onClick={() => setIsStudyMode(false)}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft className="w-5 h-5" />
              Back to Cards
            </button>
            <div className="text-sm text-gray-600">
              Card {currentCardIndex + 1} of {localCards.length}
            </div>
          </div>

          <div className="w-full aspect-[3/2] perspective-1000">
            <AnimatePresence mode="wait">
              <motion.div
                key={isFlipped ? 'back' : 'front'}
                initial={{ rotateY: isFlipped ? -180 : 0, opacity: 0 }}
                animate={{ rotateY: 0, opacity: 1 }}
                exit={{ rotateY: isFlipped ? 180 : -180, opacity: 0 }}
                transition={{ duration: 0.4 }}
                onClick={() => setIsFlipped(!isFlipped)}
                className={`w-full h-full cursor-pointer backface-hidden rounded-xl shadow-lg p-8 flex items-center justify-center text-center ${
                  isFlipped 
                    ? 'bg-indigo-50 text-gray-900' 
                    : 'bg-white text-gray-900'
                }`}
              >
                <div className="text-xl md:text-2xl">
                  {isFlipped ? localCards[currentCardIndex].back : localCards[currentCardIndex].front}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="flex justify-center gap-4 mt-8">
            <button
              onClick={() => {
                setIsFlipped(false);
                setCurrentCardIndex((i) => i === 0 ? localCards.length - 1 : i - 1);
              }}
              className="px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            >
              Previous
            </button>
            <button
              onClick={() => setIsFlipped(!isFlipped)}
              className="px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            >
              <RotateCw className="w-5 h-5" />
            </button>
            <button
              onClick={() => {
                setIsFlipped(false);
                setCurrentCardIndex((i) => i === localCards.length - 1 ? 0 : i + 1);
              }}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6">
      <div className="max-w-4xl mx-auto">
        <div className="flex flex-wrap gap-4 mb-8">
          {!readOnly && (
            <>
              <button
                onClick={handleGenerateCards}
                disabled={isGenerating}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50 flex items-center gap-2"
              >
                {isGenerating ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Generating...
                  </>
                ) : (
                  <>
                    <Wand2 className="w-5 h-5" />
                    Generate from Notes
                  </>
                )}
              </button>
              <button
                onClick={() => {
                  const newCard = {
                    id: crypto.randomUUID(),
                    front: '',
                    back: '',
                    confidence: 1 as const,
                    lastReviewed: new Date()
                  };
                  setLocalCards(prev => [...prev, newCard]);
                  onUpdate([...localCards, newCard]);
                }}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors flex items-center gap-2"
              >
                <Plus className="w-5 h-5" />
                Add Card
              </button>
            </>
          )}
          {localCards.length > 0 && (
            <button
              onClick={() => setIsStudyMode(true)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors flex items-center gap-2"
            >
              <BookOpen className="w-5 h-5" />
              Study Mode
            </button>
          )}
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {localCards.length === 0 ? (
          <div className="text-center py-12">
            <BookOpen className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">No flashcards yet</h3>
            <p className="text-gray-500 mb-6">Create flashcards manually or generate them from your notes</p>
          </div>
        ) : (
          <div className="space-y-4">
            {localCards.map((card, index) => (
              <div key={card.id} className="bg-white rounded-lg shadow-sm border p-4">
                <div className="grid md:grid-cols-2 gap-4">
                  <div>
                    <div className="flex justify-between items-center mb-1">
                      <label className="block text-sm font-medium text-gray-700">
                        Front
                      </label>
                      {readOnly && spyndlId && (
                        <button
                          onClick={(e) => handleCopyUrl(card, e)}
                          className="p-1 text-gray-500 hover:text-indigo-600 rounded-full hover:bg-indigo-50 transition-colors"
                          title="Copy card URL"
                        >
                          <Link className="w-4 h-4" />
                          {copiedUrl === card.id && (
                            <span className="absolute right-full mr-2 text-xs text-green-600 whitespace-nowrap">
                              URL copied!
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                    <textarea
                      value={card.front}
                      onChange={(e) => handleUpdateCard(index, 'front', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                      rows={3}
                      readOnly={readOnly}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Back
                    </label>
                    <textarea
                      value={card.back}
                      onChange={(e) => handleUpdateCard(index, 'back', e.target.value)}
                      className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                      rows={3}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
                {!readOnly && (
                  <div className="mt-2 flex justify-end">
                    <button
                      onClick={() => handleDeleteCard(index)}
                      className="text-red-600 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}