import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51QPqdKDsyGVnOZHc7ZET9dtbYGFEpnPOYcvkk6OLqFsZVXuE6EWA07300ycMOZcv4BoLB7zGcJHdxlxHBikOJzSG00RtkQ9r9n');

export async function createCheckoutSession(userId: string) {
  try {
    const stripe = await stripePromise;
    if (!stripe) throw new Error('Stripe failed to load');

    // Create a Checkout Session directly with Stripe
    const { error } = await stripe.redirectToCheckout({
      mode: 'subscription',
      lineItems: [{ price: 'prod_RIRk43BUbVCtwl', quantity: 1 }],
      successUrl: `${window.location.origin}/profile?success=true`,
      cancelUrl: `${window.location.origin}/profile?success=false`,
      clientReferenceId: userId,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
}