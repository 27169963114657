import React from 'react';
import { BookOpen } from 'lucide-react';
import { StudySetCard } from './StudySetCard';
import type { Spyndl } from '../types';

interface StudySetListProps {
  spyndls: Spyndl[];
  onSelect: (spyndl: Spyndl) => void;
  onDelete?: (id: string) => Promise<void>;
  onUpdate?: (id: string, updates: Partial<Spyndl>) => Promise<void>;
  emptyMessage?: string;
  isPersonal?: boolean;
  userId?: string;
}

export function StudySetList({ 
  spyndls, 
  onSelect, 
  onDelete,
  onUpdate,
  emptyMessage = 'No study sets found',
  isPersonal = false,
  userId
}: StudySetListProps) {
  // Filter out duplicates by ID
  const uniqueSpyndls = spyndls.reduce((acc, current) => {
    const exists = acc.find(item => item.id === current.id);
    if (!exists) {
      acc.push(current);
    }
    return acc;
  }, [] as Spyndl[]);

  if (uniqueSpyndls.length === 0) {
    return (
      <div className="text-center py-12">
        <BookOpen className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">{emptyMessage}</h3>
        <p className="text-gray-500">Create a study set to get started</p>
      </div>
    );
  }

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      {uniqueSpyndls.map((spyndl, index) => (
        <StudySetCard
          key={`${spyndl.id}-${index}`}
          spyndl={spyndl}
          onClick={() => onSelect(spyndl)}
          onDelete={onDelete}
          onUpdate={onUpdate}
          isPersonal={isPersonal}
          userId={userId}
        />
      ))}
    </div>
  );
}