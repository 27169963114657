import React, { useState } from 'react';
import { Wand2, Loader2, AlertCircle } from 'lucide-react';
import { generateQuizQuestions } from '../lib/gemini';
import type { Question } from '../types';

interface PracticeQuizProps {
  noteContent: string;
  spyndlId: string;
  onUpdate: (updates: Partial<any>) => Promise<void>;
}

export function PracticeQuiz({ noteContent, spyndlId, onUpdate }: PracticeQuizProps) {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<Record<number, number>>({});
  const [showResults, setShowResults] = useState(false);

  const generateQuestions = async () => {
    if (!noteContent.trim()) {
      setError('Please add some content to your notes first');
      return;
    }

    try {
      setIsGenerating(true);
      setError(null);

      const generatedQuestions = await generateQuizQuestions(noteContent);
      setQuestions(generatedQuestions);
      setSelectedAnswers({});
      setShowResults(false);
      setCurrentQuestion(0);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to generate questions';
      setError(errorMessage);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleAnswer = (questionIndex: number, answerIndex: number) => {
    if (showResults) return;
    setSelectedAnswers(prev => ({ ...prev, [questionIndex]: answerIndex }));
  };

  const calculateScore = () => {
    if (questions.length === 0) return 0;
    const correctAnswers = questions.reduce((acc, q, idx) => {
      return acc + (selectedAnswers[idx] === q.correctAnswer ? 1 : 0);
    }, 0);
    return Math.round((correctAnswers / questions.length) * 100);
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      {questions.length === 0 ? (
        <div className="text-center py-12">
          <h3 className="text-lg font-medium mb-4">Generate Practice Questions</h3>
          <p className="text-gray-600 mb-6">
            Create a quiz based on your notes to test your knowledge
          </p>
          <button
            onClick={generateQuestions}
            disabled={isGenerating || !noteContent.trim()}
            className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50 flex items-center gap-2 mx-auto"
          >
            {isGenerating ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin" />
                Generating...
              </>
            ) : (
              <>
                <Wand2 className="w-5 h-5" />
                Generate from Notes
              </>
            )}
          </button>
          {error && (
            <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p>{error}</p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="mb-6">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">
                Question {currentQuestion + 1} of {questions.length}
              </h3>
              {showResults && (
                <div className="text-lg font-medium">
                  Score: {calculateScore()}%
                </div>
              )}
            </div>
            <div className="w-full h-2 bg-gray-200 rounded-full mt-2">
              <div
                className="h-full bg-indigo-600 rounded-full transition-all duration-300"
                style={{
                  width: `${((currentQuestion + 1) / questions.length) * 100}%`,
                }}
              />
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm border p-6 mb-6">
            <p className="text-lg mb-4">{questions[currentQuestion].text}</p>
            <div className="space-y-3">
              {questions[currentQuestion].options.map((option, idx) => (
                <button
                  key={idx}
                  onClick={() => handleAnswer(currentQuestion, idx)}
                  className={`w-full p-4 text-left rounded-lg border transition-colors ${
                    selectedAnswers[currentQuestion] === idx
                      ? showResults
                        ? idx === questions[currentQuestion].correctAnswer
                          ? 'bg-green-50 border-green-200'
                          : 'bg-red-50 border-red-200'
                        : 'bg-indigo-50 border-indigo-200'
                      : 'hover:bg-gray-50 border-gray-200'
                  }`}
                  disabled={showResults}
                >
                  {option}
                </button>
              ))}
            </div>
            {showResults && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <p className="font-medium">Explanation:</p>
                <p className="mt-2 text-gray-600">
                  {questions[currentQuestion].explanation}
                </p>
              </div>
            )}
          </div>

          <div className="flex justify-between">
            <button
              onClick={() => setCurrentQuestion(prev => Math.max(0, prev - 1))}
              disabled={currentQuestion === 0}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg disabled:opacity-50"
            >
              Previous
            </button>
            {currentQuestion === questions.length - 1 ? (
              !showResults && (
                <button
                  onClick={() => setShowResults(true)}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Show Results
                </button>
              )
            ) : (
              <button
                onClick={() =>
                  setCurrentQuestion(prev => Math.min(questions.length - 1, prev + 1))
                }
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Next
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}