import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../store';
import { ArrowLeft, RotateCw } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { generateSlug } from '../lib/utils';

export function FlashcardView({ userId }: { userId: string }) {
  const { id, slug } = useParams();
  const navigate = useNavigate();
  const { spyndls } = useStore();
  const [isFlipped, setIsFlipped] = useState(false);

  const spyndl = spyndls.find(s => s.id === id);
  const card = spyndl?.deck.cards.find(c => generateSlug(c.front) === slug);

  useEffect(() => {
    if (!spyndl || !card) {
      navigate('/study-sets');
    }
  }, [spyndl, card, navigate]);

  if (!spyndl || !card) return null;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <button
        onClick={() => navigate(`/study-sets/${id}`)}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-8"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Study Set
      </button>

      <div className="w-full aspect-[3/2] perspective-1000 mb-8">
        <AnimatePresence mode="wait">
          <motion.div
            key={isFlipped ? 'back' : 'front'}
            initial={{ rotateY: isFlipped ? -180 : 0, opacity: 0 }}
            animate={{ rotateY: 0, opacity: 1 }}
            exit={{ rotateY: isFlipped ? 180 : -180, opacity: 0 }}
            transition={{ duration: 0.4 }}
            onClick={() => setIsFlipped(!isFlipped)}
            className={`w-full h-full cursor-pointer backface-hidden rounded-xl shadow-lg p-8 flex items-center justify-center text-center ${
              isFlipped ? 'bg-indigo-50 text-gray-900' : 'bg-white text-gray-900'
            }`}
          >
            <div className="text-xl md:text-2xl">
              {isFlipped ? card.back : card.front}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      <button
        onClick={() => setIsFlipped(!isFlipped)}
        className="mx-auto flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
      >
        <RotateCw className="w-5 h-5" />
        Flip Card
      </button>
    </div>
  );
}