import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { auth } from '../lib/firebase';
import { User, Settings, Mail, Key, CreditCard, Crown, Check, Loader2, AlertCircle, LogOut } from 'lucide-react';
import { PageHeader } from './PageHeader';
import { createCheckoutSession } from '../lib/stripe';
import { useSearchParams } from 'react-router-dom';

export function Profile() {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [upgradeStatus, setUpgradeStatus] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    const success = searchParams.get('success');
    if (success === 'true') {
      setUpgradeStatus('success');
    } else if (success === 'false') {
      setUpgradeStatus('error');
    }
  }, [searchParams]);

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handlePasswordReset = async () => {
    try {
      if (user?.email) {
        await auth.sendPasswordResetEmail(user.email);
        setIsChangingPassword(true);
      }
    } catch (error) {
      console.error('Error sending password reset:', error);
    }
  };

  const handleUpgrade = async () => {
    if (!user) return;
    
    try {
      setIsUpgrading(true);
      await createCheckoutSession(user.uid);
    } catch (error) {
      console.error('Error starting upgrade:', error);
      setUpgradeStatus('error');
    } finally {
      setIsUpgrading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto">
      <PageHeader title="Profile Settings" />

      {upgradeStatus === 'success' && (
        <div className="mb-6 p-4 bg-green-50 text-green-700 rounded-lg flex items-center gap-2">
          <Check className="w-5 h-5" />
          <p>Successfully upgraded to Pro! Refresh the page to see your new features.</p>
        </div>
      )}

      {upgradeStatus === 'error' && (
        <div className="mb-6 p-4 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
          <AlertCircle className="w-5 h-5" />
          <p>There was an error processing your upgrade. Please try again.</p>
        </div>
      )}

      <div className="space-y-6">
        {/* Account Section */}
        <section className="bg-white rounded-lg shadow-sm border p-6">
          <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
            <User className="w-5 h-5 text-primary-600" />
            Account
          </h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Email</p>
                <p className="text-sm text-gray-600">{user?.email}</p>
              </div>
              <Mail className="w-5 h-5 text-gray-400" />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Password</p>
                <p className="text-sm text-gray-600">••••••••</p>
              </div>
              <button 
                onClick={handlePasswordReset}
                className="text-primary-600 hover:text-primary-700"
              >
                <Key className="w-5 h-5" />
              </button>
            </div>
          </div>
        </section>

        {/* My Plan Section */}
        <section className="bg-white rounded-lg shadow-sm border p-6">
          <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
            <CreditCard className="w-5 h-5 text-primary-600" />
            My Plan
          </h3>
          <div className="space-y-6">
            <div className="flex items-start justify-between">
              <div>
                <h4 className="font-medium">Free Plan</h4>
                <p className="text-sm text-gray-600 mt-1">Basic features for personal use</p>
              </div>
              <span className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm">Current Plan</span>
            </div>

            <div className="border-t pt-6">
              <div className="bg-primary-50 rounded-lg p-6">
                <div className="flex items-center gap-3 mb-4">
                  <Crown className="w-6 h-6 text-primary-600" />
                  <h4 className="text-lg font-semibold">Upgrade to Pro</h4>
                </div>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-center gap-2 text-sm">
                    <Check className="w-4 h-4 text-primary-600" />
                    Unlimited study sets
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <Check className="w-4 h-4 text-primary-600" />
                    Advanced AI features
                  </li>
                  <li className="flex items-center gap-2 text-sm">
                    <Check className="w-4 h-4 text-primary-600" />
                    Priority support
                  </li>
                </ul>
                <button
                  onClick={handleUpgrade}
                  disabled={isUpgrading}
                  className="btn-primary w-full justify-center"
                >
                  {isUpgrading ? (
                    <>
                      <Loader2 className="w-5 h-5 animate-spin" />
                      Processing...
                    </>
                  ) : (
                    <>
                      <Crown className="w-5 h-5" />
                      Upgrade to Pro - $9.99/month
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Sign Out Button */}
        <button
          onClick={handleSignOut}
          className="btn-danger w-full justify-center"
        >
          <LogOut className="w-5 h-5" />
          Sign Out
        </button>
      </div>
    </div>
  );
}