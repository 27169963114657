import React, { useState } from 'react';
import { Upload, Wand2, Download, Loader2, AlertCircle, Trash2 } from 'lucide-react';
import { generateCoverLetter } from '../lib/gemini';
import { extractResumeText } from '../lib/utils';
import { useStore } from '../store';

interface CoverLetterWriterProps {
  userId: string;
}

export function CoverLetterWriter({ userId }: CoverLetterWriterProps) {
  const { savedDocuments, setSavedDocument } = useStore();
  const [jobDescription, setJobDescription] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [generatedLetter, setGeneratedLetter] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileUpload = async (type: 'resume' | 'coverLetterSample', file: File) => {
    try {
      const content = await extractResumeText(file);
      setSavedDocument(type, content);
      setError(null);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to read file';
      setError(message);
    }
  };

  const handleGenerate = async () => {
    if (!savedDocuments.resume) {
      setError('Please upload your resume first');
      return;
    }

    if (!jobDescription.trim()) {
      setError('Please provide a job description');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const letter = await generateCoverLetter(
        savedDocuments.resume,
        jobDescription,
        companyName,
        savedDocuments.coverLetterSample
      );
      setGeneratedLetter(letter);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to generate cover letter';
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    if (!generatedLetter) return;

    const blob = new Blob([generatedLetter], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `cover-letter-${companyName || 'company'}.txt`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const removeDocument = (type: 'resume' | 'coverLetterSample') => {
    setSavedDocument(type, '');
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-2">Cover Letter Writer</h1>
        <p className="text-gray-600">
          Upload your documents once and generate customized cover letters for any job
        </p>
      </div>

      <div className="bg-white rounded-lg shadow-sm border p-6 mb-6">
        <div className="grid md:grid-cols-2 gap-6">
          {/* Resume Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Resume {savedDocuments.resume && '(Saved)'}
            </label>
            {!savedDocuments.resume ? (
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500">
                      <span>Upload Resume</span>
                      <input
                        type="file"
                        className="sr-only"
                        accept=".pdf,.txt,.doc,.docx"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) handleFileUpload('resume', file);
                        }}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">PDF, DOC, or TXT files</p>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <span className="text-sm text-gray-600">Resume saved</span>
                <button
                  onClick={() => removeDocument('resume')}
                  className="p-1 text-red-600 hover:bg-red-50 rounded"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>

          {/* Cover Letter Sample Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Cover Letter Sample {savedDocuments.coverLetterSample && '(Saved)'}
            </label>
            {!savedDocuments.coverLetterSample ? (
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500">
                      <span>Upload Sample</span>
                      <input
                        type="file"
                        className="sr-only"
                        accept=".pdf,.txt,.doc,.docx"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) handleFileUpload('coverLetterSample', file);
                        }}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">PDF, DOC, or TXT files</p>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <span className="text-sm text-gray-600">Sample saved</span>
                <button
                  onClick={() => removeDocument('coverLetterSample')}
                  className="p-1 text-red-600 hover:bg-red-50 rounded"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Company Name (Optional)
          </label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            placeholder="Enter company name"
          />
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Job Description
          </label>
          <textarea
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            rows={6}
            placeholder="Paste the job description here"
          />
        </div>

        {error && (
          <div className="mt-6 p-3 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
            <AlertCircle className="w-5 h-5 flex-shrink-0" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        <button
          onClick={handleGenerate}
          disabled={isLoading || !jobDescription.trim()}
          className="w-full mt-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50 flex items-center justify-center gap-2"
        >
          {isLoading ? (
            <>
              <Loader2 className="w-5 h-5 animate-spin" />
              Generating...
            </>
          ) : (
            <>
              <Wand2 className="w-5 h-5" />
              Generate Cover Letter
            </>
          )}
        </button>
      </div>

      {generatedLetter && (
        <div className="bg-white rounded-lg shadow-sm border p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold">Generated Cover Letter</h2>
            <button
              onClick={handleDownload}
              className="px-3 py-1 text-indigo-600 hover:bg-indigo-50 rounded-lg flex items-center gap-1"
            >
              <Download className="w-4 h-4" />
              Download
            </button>
          </div>
          <div className="prose max-w-none">
            <pre className="whitespace-pre-wrap font-sans">{generatedLetter}</pre>
          </div>
        </div>
      )}
    </div>
  );
}