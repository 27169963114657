import React, { useState, useEffect } from 'react';
import { useStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { Clock, TrendingUp } from 'lucide-react';
import { Spyndl } from '../types';
import { generateStudySetUrl } from '../lib/utils';
import { StudySetList } from './StudySetList';
import { SearchBar } from './SearchBar';
import { PageHeader } from './PageHeader';

interface DiscoverProps {
  userId: string;
}

export function Discover({ userId }: DiscoverProps) {
  const { spyndls, recentlyViewed, addToRecentlyViewed, initializeListeners } = useStore();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userId) {
      const cleanup = initializeListeners(userId);
      return () => cleanup();
    }
  }, [userId, initializeListeners]);

  // Filter for public study sets
  const publicSpyndls = spyndls.filter(spyndl => 
    spyndl.isPublic && 
    (spyndl.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    spyndl.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  // Get popular study sets (based on number of saves)
  const popularSpyndls = [...publicSpyndls]
    .sort((a, b) => (b.saves || 0) - (a.saves || 0))
    .slice(0, 6);

  const handleSelect = (spyndl: Spyndl) => {
    const url = generateStudySetUrl(spyndl.title, spyndl.note.content);
    addToRecentlyViewed(spyndl);
    navigate(`/discover/${url}`);
  };

  return (
    <div className="max-w-7xl mx-auto">
      <PageHeader title="Discover Study Sets" />

      <div className="mb-8">
        <SearchBar
          value={searchTerm}
          onChange={setSearchTerm}
          placeholder="Search public study sets..."
        />
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      {!searchTerm && (
        <>
          {/* Recently Viewed Section */}
          {recentlyViewed.length > 0 && (
            <section className="mb-12">
              <div className="flex items-center gap-2 mb-4">
                <Clock className="w-5 h-5 text-primary-600" />
                <h3 className="text-lg font-semibold text-gray-900">Recently Viewed</h3>
              </div>
              <StudySetList
                spyndls={recentlyViewed}
                onSelect={handleSelect}
                emptyMessage="No recently viewed study sets"
                userId={userId}
              />
            </section>
          )}

          {/* Popular Study Sets Section */}
          {popularSpyndls.length > 0 && (
            <section className="mb-12">
              <div className="flex items-center gap-2 mb-4">
                <TrendingUp className="w-5 h-5 text-primary-600" />
                <h3 className="text-lg font-semibold text-gray-900">Popular Study Sets</h3>
              </div>
              <StudySetList
                spyndls={popularSpyndls}
                onSelect={handleSelect}
                emptyMessage="No popular study sets yet"
                userId={userId}
              />
            </section>
          )}
        </>
      )}

      {/* Search Results */}
      {searchTerm && (
        <section>
          <h3 className="text-lg font-semibold text-gray-900 mb-4">
            Search Results
          </h3>
          <StudySetList
            spyndls={publicSpyndls}
            onSelect={handleSelect}
            emptyMessage="No study sets found"
            userId={userId}
          />
        </section>
      )}
    </div>
  );
}